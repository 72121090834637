import { createSelector } from 'reselect'

export const getNabolagState = (state) => state.nabolag

export const getNabolagInfo = createSelector([getNabolagState], (nabolag) => {
  const error = {
    error: {
      code: 500,
      message: 'Kunde ikke lese data',
      type: 'COULD_NOT_READ_DATA',
    },
  }
  if (nabolag.error && nabolag.error.type) {
    return { error: nabolag.error }
  }
  if (!nabolag) {
    return error
  }
  if (!nabolag.data) {
    return error
  }
  if (nabolag.data) {
    if (!nabolag.data.general) {
      return error
    }
  }
  return nabolag.data.general.info
})

export const getNabolagCategories = createSelector([getNabolagState], (nabolag) => {
  if (
    nabolag &&
    nabolag.data &&
    nabolag &&
    nabolag.data.general &&
    nabolag.data.general.categories
  ) {
    return nabolag.data.general.categories
  }
  return []
})

export const getFactCards = createSelector([getNabolagState], (nabolag) => {
  if (!nabolag || !nabolag.data || !nabolag.data.general) {
    return []
  }
  return nabolag.data.general.factCards
})

export const getRatingCards = createSelector([getNabolagState], (nabolag) => {
  if (!nabolag || !nabolag.data || !nabolag.data.general) {
    return []
  }
  return nabolag.data.general.ratingCards
})

export const getRouteCalculation = createSelector([getNabolagState], (nabolag) => {
  if (!nabolag || !nabolag.data || !nabolag.data.general) {
    return {}
  }
  return nabolag.data.general.routeCalculation
})

export const getInfoText = createSelector([getNabolagState], (nabolag) => {
  if (!nabolag || !nabolag.data || !nabolag.data.general || !nabolag.data.general.infoText) {
    return []
  }
  return nabolag.data.general.infoText
})

export const getMediaCards = createSelector([getNabolagState], (nabolag) => {
  if (!nabolag || !nabolag.data || !nabolag.data.general || !nabolag.data.general.mediaCards) {
    return {}
  }
  return nabolag.data.general.mediaCards
})

export const getShowCompareBox = createSelector([getNabolagState], (nabolag) => {
  if (!nabolag || !nabolag.data || !nabolag.data.general || !nabolag.data.general.features) {
    return false
  }
  return nabolag.data.general.features.showCompareBox
})

export const getShowCardListArrowsOnMobile = createSelector([getNabolagState], (nabolag) => {
  if (!nabolag?.data?.general?.features) {
    return false
  }
  return nabolag.data.general.features.showCardListArrowsOnMobile
})

export const getShowRouteCalculation = createSelector([getNabolagState], (nabolag) => {
  if (!nabolag?.data?.general?.features) {
    return false
  }
  return nabolag.data.general.features.showRouteCalculation
})

export const getMapData = createSelector([getNabolagState], (nabolag) => {
  if (!nabolag || !nabolag.data || !nabolag.data.general || !nabolag.data.general.map) {
    return {}
  }
  return nabolag.data.general.map
})

export const getNabolagTheme = createSelector([getNabolagState], (nabolag) => {
  if (!nabolag || !nabolag.data || !nabolag.data.general || !nabolag.data.general.theme) {
    return {}
  }
  return nabolag.data.general.theme
})
