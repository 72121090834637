/* eslint-disable camelcase */
/**
 * Parse the query and return legal params
 *
 * @param query
 */

const getValidQueryParams = (query, includeMarketing = false) => {
  const params = (({
    t,
    app,
    // marketing
    scrollToId,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    scrolled,
  }) => ({
    t,
    app,
    ...(includeMarketing
      ? {
          // marketing
          scrollToId,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
          scrolled,
        }
      : {}),
  }))(query)
  // Use this method to remove undefined properties
  return JSON.parse(JSON.stringify(params))
}

export default getValidQueryParams
