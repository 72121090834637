import getValidQueryParams from './getValidQueryParams'

const generateURL = (url, params, includeMarketing = false) => {
  const validParams = getValidQueryParams(params, includeMarketing)

  let delimiter = ''
  if (Object.keys(validParams).length > 0) {
    delimiter = (url || '').indexOf('?') === -1 ? '?' : '&'
  }

  const queryString = Object.keys(validParams)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&')
  return `${url}${delimiter}${queryString}`
}

export default generateURL
