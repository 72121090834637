import slugify from 'slugify'
import { i18n } from '../../../../i18n'

const emptyFiltered = []
export const getSkiTracks = (state, props) => {
  const { skiTracks } = state.nabolag
  let filtered = skiTracks
  if (props.query) {
    if (props.query.filter) {
      const { filter } = props.query
      const translatedSlug = slugify(i18n.t(`map:filters.skiTracks`), {
        lower: true,
        remove: /\./g,
      })
      if (translatedSlug !== filter) {
        filtered = emptyFiltered
      }
    }
  }

  return { tracks: skiTracks, filtered }
}
