import { createSelector } from 'reselect'

export const getBusinessState = state => {
  if (state.nabolag.error) {
    return null
  }
  return state.nabolag.data.businesses
}

export const getBusinessData = createSelector(
  [getBusinessState],
  businesses => {
    if (!businesses) {
      return null
    }
    const res = {
      all: [],
      ratings: [],
      pois: [],
    }
    for (let i = 0; i < businesses.length; i += 1) {
      const item = businesses[i]
      if (item.values || item.value) {
        res.all.push({
          id: item.id,
          type: item.type,
          data: item.values || item.value,
        })
      } else if (item.type === 'RATING' || item.type === 'POIS' || item.type === 'POIS_NOMAP') {
        res.all.push(item)
        if (item.type === 'RATING') {
          res.ratings.push(item)
        } else {
          res.pois.push(item)
        }
      }
    }
    return res
  },
)
