import { createSelector } from 'reselect'

export const getPeopleState = state => {
  if (state.nabolag.error) {
    return null
  }
  return state.nabolag.data.people
}

export const getPeopleData = createSelector([getPeopleState], people => {
  if (!people) {
    return null
  }
  const res = {
    all: [],
    ratings: [],
    pois: [],
  }
  for (let i = 0; i < people.length; i += 1) {
    const item = people[i]
    if (item.type === 'AGE_DISTRIBUTION') {
      if (item.stats) {
        res.all.push({
          id: item.id,
          type: item.type,
          data: {
            stats: item.stats,
            barValues: item.values,
          },
        })
      } else {
        res.all.push({
          id: item.id,
          type: item.type,
          data: item.values,
        })
      }
    } else if (item.values || item.value) {
      res.all.push({
        id: item.id,
        type: item.type,
        data: item.values || item.value,
      })
    } else if (item.type === 'RATING' || item.type === 'POIS') {
      res.all.push(item)
      if (item.type === 'RATING') {
        res.ratings.push(item)
      } else {
        res.pois.push(item)
      }
    }
  }
  return res
})
