import { createSelector } from 'reselect'
import slugify from 'slugify'

import mapFilters from '../../../models/MapFilters'
import { i18n } from '../../../../i18n'

const getGeneralNabolagState = state => state.nabolag.data

const getNabolagPoiState = (state, props) => {
  if (props.query) {
    if (props.query.filter) {
      const { filter } = props.query
      const len = mapFilters.length
      let validPoiIdTypes = []
      for (let i = 0; i < len; i += 1) {
        const f = mapFilters[i]
        const translatedSlug = slugify(i18n.t(`map:filters.${f.name}`), {
          lower: true,
          remove: /\./g,
        })
        if (translatedSlug === filter) {
          validPoiIdTypes = f.poiTypeIds
          break
        }
      }
      const filtered = state.nabolag.pois.filter(poi => validPoiIdTypes.indexOf(poi.poiTypeId) > -1)
      return {
        all: state.nabolag.pois || [],
        filtered,
      }
    }
  }

  return {
    all: state.nabolag.pois || [],
    filtered: state.nabolag.pois || [],
  }
}

export const getNabolagPois = createSelector(
  [getNabolagPoiState, getGeneralNabolagState],
  (pois, nabolag) => {
    if (!nabolag.general) {
      return {
        all: [],
        filtered: [],
        closest: [],
      }
    }
    const { location } = nabolag.general ? nabolag.general.info : null
    const poiLen = pois.filtered.length
    let closestPoi = null
    if (location) {
      let minDiff = 999
      for (let i = 0; i < poiLen; i += 1) {
        const poi = pois.filtered[i]
        const latDiff = Math.abs(location.lat - poi.location.lat)
        const lngDiff = Math.abs(location.long - poi.location.lng)
        const diff = latDiff + lngDiff
        if (diff < minDiff) {
          minDiff = diff
          closestPoi = poi
        }
      }
    }

    return {
      all: pois.all,
      filtered: pois.filtered,
      closest: closestPoi,
    }
  },
)

export default getNabolagPois
