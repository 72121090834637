// Create categories/filters for the map view by grouping poiTypeIds together
const mapFilters = [
  {
    name: 'publicTransport',
    poiTypeIds: [210, 211, 220, 221, 222, 223, 230, 235, 240, 241, 250],
  },
  {
    name: 'schools',
    poiTypeIds: [401, 402, 500],
  },
  {
    name: 'groceries',
    poiTypeIds: [700, 900, 1000, 1100],
  },
  {
    name: 'activities',
    poiTypeIds: [350],
  },
  {
    name: 'sports',
    poiTypeIds: [310, 330],
  },
  {
    name: 'kindergardens',
    poiTypeIds: [600],
  },
  {
    name: 'higherEducation',
    poiTypeIds: [550, 551],
  },
  {
    name: 'greenEnvironment',
    poiTypeIds: [270, 280],
  },
  {
    name: 'winterSports',
    poiTypeIds: [355, 357, 358],
  },
  {
    name: 'harbours',
    poiTypeIds: [360],
  },
  {
    name: 'taxiAndParking',
    poiTypeIds: [260, 2500],
  },
  {
    name: 'skiTracks',
    poiTypeIds: [],
  },
]

export default mapFilters
