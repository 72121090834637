import { createSelector } from 'reselect'

export const getShoppingState = state => {
  if (state.nabolag.error) {
    return null
  }
  return state.nabolag.data.shopping
}

export const getShoppingData = createSelector([getShoppingState], shopping => {
  if (!shopping) {
    return null
  }
  const res = {
    all: [],
    ratings: [],
    pois: [],
  }
  for (let i = 0; i < shopping.length; i += 1) {
    const item = shopping[i]
    if (item.values || item.value) {
      res.all.push({
        id: item.id,
        type: item.type,
        data: item.values || item.value,
      })
    } else if (item.type === 'RATING' || item.type === 'POIS') {
      res.all.push(item)
      if (item.type === 'RATING') {
        res.ratings.push(item)
      } else {
        res.pois.push(item)
      }
    }
  }
  return res
})
