import { createSelector } from 'reselect'

export const getEnvironmentState = state => {
  if (state.nabolag.error) {
    return null
  }
  return state.nabolag.data.environment
}

export const getEnvironmentData = createSelector([getEnvironmentState], environment => {
  if (!environment) {
    return null
  }
  const res = {
    suncurves: null,
    all: [],
    ratings: [],
    pois: [],
  }
  for (let i = 0; i < environment.length; i += 1) {
    const item = environment[i]
    if (item.type === 'SUNCURVES') {
      res.all.push({ id: item.id, type: item.type })
    } else if (item.values || item.value) {
      res.all.push({
        id: item.id,
        type: item.type,
        data: item.values || item.value,
      })
    } else if (item.type === 'RATING' || item.type === 'POIS') {
      res.all.push(item)
      if (item.type === 'RATING') {
        res.ratings.push(item)
      } else {
        res.pois.push(item)
      }
    }
  }
  return res
})
